// javascript/controllers/payment_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["customerFinancingForm", "customerFinancingFormSimple"];
  fields_with_errors = [];

  addFinancingApplication(event) {
    event.preventDefault();
    let financing_form_url = null;
    let form_data = null;
    let message_body = "customer-financing-modal-body"
    let is_simple = event.target.dataset.simple === "true"
    if (is_simple) {
      this.setFinancingCalculations(true);
      financing_form_url = this.customerFinancingFormSimpleTarget.action;
      form_data = new FormData(this.customerFinancingFormSimpleTarget);
      message_body = "customer-financing-simple-modal-body"
    }else {
      this.setFinancingCalculations();
      financing_form_url = this.customerFinancingFormTarget.action;
      form_data = new FormData(this.customerFinancingFormTarget);
    }
    let this_controller = this;
    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LfeUeApAAAAABieahRRukW2_HMYFw-jTuJLBNpY")
        .then(function (token) {
          form_data.append("g-recaptcha-response", token);
          Rails.ajax({
          type: "post",
          url: financing_form_url,
          data: form_data,
          success: (response) => {
            let { status, data } = response;
            if (status === "SUCCESS") {
              let container = document.getElementById(message_body);
              container.innerHTML = `${data}<button type="button" class="btn btn-secondary mt-3" data-bs-dismiss="modal">Sulje</button></div>`;
            }
          },
          error: (response) => {
            let error_fields = Object.keys(response);
            if(is_simple){
              error_fields = error_fields.map((field_name) => `car_finance_${field_name}`);
            }
            error_fields.forEach((field_name) => {
              let ele = document.getElementById(field_name);
              if (ele) {
                ele.classList.add("is-invalid");
              }
            });
            this_controller.fields_with_errors = error_fields;
            if (error_fields.length > 0) {
              let first_element = document.getElementById(error_fields[0]);
              first_element.scrollIntoView();
            }
          },
        });           
      });
    });
  }

  setFinancingCalculations(simple = false) {
    let down_payment = document.getElementById("down_payment_input");
    let period = document.getElementById("credit_period_input");
    let percentage = document.getElementById("credit_percentage_input");
    if (down_payment && period && percentage) {
      if(simple){
        document.getElementById("car_finance_deposit").value =
            down_payment.value;
        document.getElementById("car_finance_months").value = period.value;
        document.getElementById("car_finance_last_payment").value =
            percentage.value;
      }else {
        document.getElementById("financing_selected_deposit").value =
            down_payment.value;
        document.getElementById("financing_selected_months").value = period.value;
        document.getElementById("financing_selected_last_payment").value =
            percentage.value;
      }
    }
  }

  removeErrorIndicator(event) {
    let ele_id = event.target.id;
    if (
      this.fields_with_errors.length > 0 &&
      ele_id &&
      this.fields_with_errors.indexOf(ele_id) >= 0
    ) {
      let current_element = document.getElementById(ele_id);
      if (current_element) {
        current_element.classList.remove("is-invalid");
      }
    }
  }
}
